<template>
  <div>
    <el-form
      ref="noteRef"
      :model="form"
      :rules="rules"
      hide-required-asterisk>
      <el-form-item label="Nota" prop="note">
        <el-input class="mb-4" type="textarea" :rows="4" v-model="form.value" />
      </el-form-item>
    </el-form>
    <div class="w-full flex flex-row" style="justify-content: right;">
      <div class="flex flex-row mr-4" style="align-items: center;">
        <strong class="mr-1">Privada</strong>
        <el-switch v-model="form.isPrivate" :disabled="!!note.id" />
      </div>
      <sa-button
        class="rounded text-bold mr-2"
        custom-class="w-full"
        type="secondary"
        @click="cancel">
        Cancelar
      </sa-button>
      <sa-button
        class="rounded text-bold"
        custom-class="w-full"
        type="primary"
        @click="save">
        Guardar
      </sa-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Note',
  props: {
    note: {
      type: Object,
      default: () => ({
        id: null,
        value: null,
        isPrivate: true,
      }),
    },
  },
  data() {
    return {
      form: {
        id: null,
        value: null,
        isPrivate: true,
      },
      rules: {
        value: [{ required: true, type: 'string', message: 'No es posible guardar notas vacias', trigger: 'change' }],
      },
    }
  },
  mounted() {
    this.form = {
      id: this.$props.note.id,
      value: this.$props.note.note,
      isPrivate: this.$props.note.isPrivate,
    };
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    save() {
      this.$refs['noteRef'].validate(async (isValidForm) => {
        if (!isValidForm) return;
        this.$emit('onSave', this.form);
      });
    },
  },
}
</script>
